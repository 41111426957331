<template>
  <b-row class="">
    <b-col cols="12 mb-1">
      <b-row>
        <b-col
          cols="3"
          style="margin-top:2rem"
        >
          <b-form-checkbox
            :checked="isGrupoSelecionado()"
            switch
            @change="checkAllGrupo()"
          >
            <h6
              style="margin-top: 0.15rem"
              class="text-uppercase"
            >
              {{ grupo.descricao }}
            </h6>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="recurso in recursos"
          :key="recurso.nome"
          cols="3"
        >
          <b-form-checkbox
            :checked="selecionado(recurso.nome)"
            style="margin-top: 0.75rem"
            switch
            @change="selecionar(recurso.nome)"
          >
            {{ recurso.descricao }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  nome: 'GrupoPermissao',
  props: {
    recursos: {
      type: Array,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    selecionados: {
      type: Array,
      default: () => [],
    },
    isAdm: {
      type: Boolean,
    },
  },
  data() {
    return {
      selecionadosIn: [],
    }
  },
  created() {
    this.selecionadosIn = this.selecionados
  },
  methods: {
    isGrupoSelecionado() {
      return this.recursos.every(every => this.selecionados.includes(every.nome))
    },
    checkAllGrupo() {
      const allSelected = this.isGrupoSelecionado()
      this.selecionados = this.selecionados.filter(filter => this.recursos.findIndex(f => f.nome === filter < 0))

      this.recursos.forEach(recurso => {
        if (!allSelected) {
          if (this.selecionados.indexOf(recurso.nome) === -1) {
            this.selecionados.push(recurso.nome)
          }
        } else {
          const index = this.selecionados.findIndex(s => s === recurso.nome)
          if (index !== -1) {
            this.selecionados.splice(index, 1)
          }
        }
      })
      this.$emit('checkAll', this.selecionados)
    },
    selecionar(recurso) {
      const index = this.selecionados.indexOf(recurso)
      if (index >= 0) {
        this.selecionados.splice(index, 1)
      } else {
        this.selecionados.push(recurso)
      }
      this.$emit('check', this.selecionados)
    },
    selecionado(recurso) {
      return this.selecionados.includes(recurso)
    },
  },
}
</script>
